
export async function login(usernaeme:string, password:string){
    const resp = await fetch('/api/users/login',{
        method:'POST',
        headers:{
            'Content-Type':'application/json'
        },
        body:JSON.stringify({
            username:usernaeme,
            password:password
        })
    });
    const data = await resp.json();
    if(resp.ok){
        localStorage.setItem('token',data.data);
        return data;
    }else{
        return null;
    }
}

export async function getRoomToken(){
    const token = localStorage.getItem('token');
    
    const resp = await fetch('/api/volc/room-token',{
        headers:{
            'Content-Type':'application/json',
            'Authorization':`Bearer ${token}`
        }
    });

    const data = await resp.json();
    return data;
}


export async function joinBot(){
    const token = localStorage.getItem('token');
    const resp = await fetch('/api/volc/start',{
        headers:{
            'Content-Type':'application/json',
            'Authorization':`Bearer ${token}`
        }
    });

    return await resp.json();
}


export async function removeBot(){
    const token = localStorage.getItem('token');
    const resp = await fetch('/api/volc/end',{
        headers:{
            'Content-Type':'application/json',
            'Authorization':`Bearer ${token}`
        }
    });

    return await resp.json();
}
