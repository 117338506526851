/**
 * Copyright 2024 Beijing Volcano Engine Technology Co., Ltd. All Rights Reserved.
 * SPDX-license-identifier: BSD-3-Clause
 */

import React from 'react';
import IDividerProps from './type';
import DividerWrapper from './style';

const DividerItem: React.FC<IDividerProps> = (props) => {
  return (
    <DividerWrapper {...props} />
  );
};

export default DividerItem;