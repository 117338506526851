import React, { useState, useContext, useEffect, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { Context } from '../../context';
import { Input, Button, Form, Modal } from 'antd';
import * as Api from '../../api';
type IForm = { username: string; password: string };

const ModalWrapper = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    padding: 15px 32px 0px;
  }
  .ant-modal-body {
    padding: 14px 32px 24px;
  }
  .ant-modal-content {
    height: 365px;
    border-radius: 8px;
  }
  .ant-modal-title {
    font-family: PingFang SC;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 80px;
    text-align: left;
    height: 80px;
  }
  .ant-modal-header {
    border-radius: 6px 6px 0 0;
  }
`;




const UserLogin:React.FC<Record<string, unknown>> = () => {
    const { password, username,autoLogin,mode, setUsername, setPassword,message } = useContext(Context);
    const [form] = Form.useForm<IForm>();
    const [joinFailReason,setJoinFailReason] = useState('');
    const onFinish = async (value:IForm)=>{
        console.log('value',value);
        const { username, password } = value;
        const result = await Api.login(username,password);
        if(result.code== 200){
            location.href = '/';

            return;
        }
        setJoinFailReason(result.message);
    };

    useEffect( ()=>{
        if(autoLogin){
            onFinish({username,password});
        }
    },[autoLogin]);


    useEffect(()=>{
        if(message == 'join'){
            onFinish({username,password});
        }
    },[message]);


  return (
    <ModalWrapper width={390} title="登录" visible={true} closable={false} footer={null} centered>
        <Form form={form} onFinish={onFinish} initialValues={{ password, username }} aria-disabled ={mode == 'auto'} >
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                validator: (_, value) => {
                    if(value.length === 0){
                        setJoinFailReason('用户名不能为空');
                        return Promise.reject('用户名不能为空');
                    }
                    return Promise.resolve();
                },
              },
            ]}
          >
            <Input placeholder="用户名" size="large" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                validator: (_, value) => {
                    if(value.length === 0){
                        setJoinFailReason('用户名不能为空');
                        return Promise.reject('用户名不能为空');
                    }
                    return Promise.resolve();
                },
              },
            ]}
          >
            <Input placeholder="用户ID" size="large" />
          </Form.Item>
          <div style={{ color: 'red'}}>
            {joinFailReason}
          </div>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              block
              size="large"
              style={{ marginTop: 15, fontWeight: 600 }}
            >
              登录
            </Button>
          </Form.Item>
        </Form>
      </ModalWrapper>
  );
}; 


export default UserLogin;