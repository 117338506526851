
import React from 'react';
import { IBotProps } from './type';
import IconBlock from 'src/components/IconBlock';

import BotIcon from 'src/assets/images/bot.png';
import styled from 'styled-components';


const GreatDiv = styled.div`
    filter: grayscale(100%);
`;

const BotControl: React.FC<IBotProps> = ({ changeHooks, hasBot }) => {
  return (
    <IconBlock onClick={() => changeHooks && changeHooks()} size={20}>
      {hasBot? <img src={BotIcon}/>: <GreatDiv><img src={BotIcon} /></GreatDiv>}
    </IconBlock>
  );
};

export default BotControl;